import React, { useContext } from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

import makeStyles from '@material-ui/core/styles/makeStyles'

import { useStaticQuery, graphql, Link } from "gatsby"
import LangContext from "../../contexts/LangContext"

import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"

import CustomButton from "../buttons/CustomButton"

const useStyles = makeStyles(theme => ({
  label: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.primary.contrastText,
    textAlign: "center",
    padding: '2px 6px',
    borderRadius: 2,
    fontWeight: 700,
    fontSize: 12,
    position: 'absolute',
    top: 6,
    left: 6,
    zIndex: 9999,
  },
  mediaWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    position: "relative"
  },
  media: {
    backgroundSize: "contain",
    height: 140,
    marginBottom: theme.spacing(1),
    backgroundPosition: "center",

    [theme.breakpoints.down("sm")]: {
      height: 300,
    },

    [theme.breakpoints.down("xs")]: {
      height: 160,
    },
  },

  wrapper: {
    height: 160,
  },

  title: {
    fontSize: 20,
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    color: theme.palette.common.black
  },

  body: {
    fontSize: 16,
    height: 100,
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 4,
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
  },

  button: {
    marginBottom: theme.spacing(1),

    "&:last-child": {
      marginBottom: 0,
    },
  },
}))

const ProcedureDetailCard = ({ procedure, showLabel }) => {
  const classes = useStyles()

  const lang = useContext(LangContext)

  const data = useStaticQuery(query)

  const procedurePage = data.proceduresPages.edges
    .map(pp => pp.node)
    .filter(pp => pp.lang === lang.locale)
    .pop()
  
  const getIcon = () => {
    return procedure.data.categories[0].category.document.data.icon
  }

  const getSlug = () => {
    return `/${procedurePage.uid}/${procedure.data.categories[0].category.document.uid}/${procedure.uid}/`
  }

  const getBody = () => {
    let body = ""
    procedure.data.body1
      .filter(b => b.id)
      .forEach(b => {
        body = `${body} ${b.primary.content.text}`
      })

    return body
  }

  return (
    <Card elevation={0} square>
      <div className={classes.mediaWrapper}>
        {showLabel && <span className={classes.label}>Proceduri</span>}
        <CardMedia>
          <GatsbyImage
            loading="eager"
            className={classes.media}
            image={getIcon().gatsbyImageData}
            alt={getIcon().alt ?? ""}
          />
        </CardMedia>
      </div>
      <div className={classes.wrapper}>
        <Typography 
          component={Link}
          to={getSlug()} 
          variant="h5" 
          className={classes.title}
        >
          {procedure.data.title.text}
        </Typography>
        <Typography component="div" variant="body1" className={classes.body}>
          {getBody()}
        </Typography>
      </div>
      <br />
      <CustomButton
        color="primary"
        variant="contained"
        fullWidth
        component={Link}
        to={getSlug()}
      >
        Vezi mai mult
      </CustomButton>
    </Card>
  )
}

export default ProcedureDetailCard

const query = graphql`
  query ProcedureDetailQuery {
    proceduresPages: allPrismicProcedures {
      edges {
        node {
          uid
          lang
          data {
            title
          }
        }
      }
    }
  }
`

ProcedureDetailCard.defaultProps = {
  showLabel: false,
}

ProcedureDetailCard.propTypes = {
  procedure: PropTypes.object.isRequired,
  showLabel: PropTypes.bool,
}
